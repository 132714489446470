import moment from 'moment';
import CONFIG from 'config';
import axios from 'axios';
import { escapeRegExp, flattenQuery, queryWithSearchParamsOnly } from 'utils/string-mapper/string-mapper';
import { isQueryIntentEnabled, getNewSearchTerm } from 'utils/queryintent/queryintent';
import { setAppliedFilters, formatFilters, reconstructTreeStructure, splitDateRangeString } from 'utils/filters/filters';
import { qaKpCmsIds } from 'redux/api/knowledgeAi/knowledgeAi';
import FILTERS from 'config-filters';

export const name = 'knowledge';

export const API_KNOWLEDGE_SEARCH_PENDING = 'API_KNOWLEDGE_SEARCH_PENDING';
export const API_KNOWLEDGE_SEARCH_FAILURE = 'API_KNOWLEDGE_SEARCH_FAILURE';
export const API_KNOWLEDGE_SEARCH_SUCCESS = 'API_KNOWLEDGE_SEARCH_SUCCESS';
export const API_SEARCH_CANCELLED = 'API_SEARCH_CANCELLED';
export const API_KNOWLEDGE_LOAD_MORE_PENDING = 'API_KNOWLEDGE_LOAD_MORE_PENDING';
export const API_KNOWLEDGE_LOAD_MORE_FAILURE = 'API_KNOWLEDGE_LOAD_MORE_FAILURE';
export const API_KNOWLEDGE_LOAD_MORE_SUCCESS = 'API_KNOWLEDGE_LOAD_MORE_SUCCESS';
export const API_SLIDE_LEVEL_SEARCH_PENDING = 'API_SLIDE_LEVEL_SEARCH_PENDING';
export const API_SLIDE_LEVEL_SEARCH_FAILURE = 'API_SLIDE_LEVEL_SEARCH_FAILURE';
export const API_SLIDE_LEVEL_SEARCH_SUCCESS = 'API_SLIDE_LEVEL_SEARCH_SUCCESS';
export const API_INIT_SLIDE_LEVEL_SEARCH_PENDING = 'API_INIT_SLIDE_LEVEL_SEARCH_PENDING';
export const API_INIT_SLIDE_LEVEL_SEARCH_FAILURE = 'API_INIT_SLIDE_LEVEL_SEARCH_FAILURE';
export const API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS = 'API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS';
export const SET_KNOWLEDGE_APPLIED_FILTERS_PENDING = 'SET_KNOWLEDGE_APPLIED_FILTERS_PENDING';
export const SET_KNOWLEDGE_APPLIED_FILTERS_SUCCESS = 'SET_KNOWLEDGE_APPLIED_FILTERS_SUCCESS';
export const SET_KNOWLEDGE_APPLIED_FILTERS_FAILED = 'SET_KNOWLEDGE_APPLIED_FILTERS_FAILED';
export const SET_KNOWLEDGE_ACTIVE_FILTER_INDEX = 'SET_KNOWLEDGE_ACTIVE_FILTER_INDEX';
export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM';
export const CHANGE_SORTER = 'CHANGE_SORTER';
export const SET_QUERY_INTENT_APPLIED = 'SET_QUERY_INTENT_APPLIED';
export const API_KNOWLEDGE_SEARCH_LASTSEARCH = 'API_KNOWLEDGE_SEARCH_LASTSEARCH';
export const API_KNOWLEDGE_SEARCH_LASTSEARCH_FAIL = 'API_KNOWLEDGE_SEARCH_LASTSEARCH_FAIL';
export const CHANGE_SEARCH_TERM_QI = 'CHANGE_SEARCH_TERM_QI';
export const SET_KNOWLEDGE_AUTO_CORRECT_STATE = 'SET_KNOWLEDGE_AUTO_CORRECT_STATE';
export const SET_KNOWLEDGE_UNDO_QUERY_INTENTS = 'SET_KNOWLEDGE_UNDO_QUERY_INTENTS';
export const TOGGLE_AND_FILTER = 'TOGGLE_AND_FILTER';
export const APPLY_AND_FILTERS = 'APPLY_AND_FILTERS';
export const CLEAR_PENDING_AND_FILTERS = 'CLEAR_PENDING_AND_FILTERS';
export const KNOWLEDGE_SET_SHOW_CHATBOT = 'KNOWLEDGE_SET_SHOW_CHATBOT';

const sources = [];
const initialState = {
  loading: true,
  loadMorePending: false,
  error: false,
  errorMessage: '',
  empty: false,
  previousSearch: { query: {} },
  lastSearch: { query: {} },
  results: [],
  totalCount: 0,
  pageCount: 0,
  currentPage: CONFIG.DEFAULT_CURRENT_PAGE,
  filters: [],
  appliedQueryParams: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilters: {}, // object ready for qs to convert to a string in string-mapper.js
  activeFilter: '',
  query: queryWithSearchParamsOnly(),
  queryIntents: [],
  qiApplied: false,
  qiOriginal: '',
  autoCorrectMode: true,
  autoCorrect: {
    isSearchTermCorrected: false,
    correctedSearchTerm: ''
  },
  andFilters: {},
  pendingAndFilters: {},
  showChatbot: false,
  currentLastResult: 0,
};

export const mapGenAIEnblRetrievalResponse = (results) => {
  return results?.map((item, i) => {

    let kpCmsId = item.crawl_item_id;
    if (CONFIG.ENVIRONMENT !== 'prod' && CONFIG.ENVIRONMENT !== 'stg' && i < qaKpCmsIds.length) {
      kpCmsId = qaKpCmsIds[i];
    }

    return {
      'docId': kpCmsId,
      'docRank': item.rank,
      'docType': item.doc_type,
      'title': item.title,
      'materialDesc': item.material_desc || '',
      'materialDescHtml': item.material_desc || '',
      'resultType': item.contenttype,
      'modified': item.modified_date,
      'dateRevised': item.doc_updated_date,
      'datePublished': item.original_published_date,
      'globalRelevance': 1,
      'fileName': item.filename,
      'materialId': item.material_id,
      'attachmentId': item.material_attachment_id,
      'kbCmsId': item.kb_cms_id,
      'kpCmsId': kpCmsId,
      'allAuthors': item.authors_json?.map((author) => (
        {
          'staffId': author.staff_id,
          'firstName': author.firstname,
          'lastName': author.lastname,
          'email': author.email,
          'role': author.role,
          'roleSortOrder': author.role_sort_order,
          'hrEmployeeId': author.hr_emp_id,
          'additionalContact': author.isadditionalcontact
        }
      )) || [],
      'urlTitle': item.url_title,
      'materialUrl': item.material_url,
      'industryAllPAs': item.allindustrypas?.map((pa) => (
        {
          'topicId': pa.topic_id,
          'keywordTopic': pa.keywordtopic,
          'topicNameAlias': pa.topic_name_alias,
          'parentId': pa.parent_id,
          'hierarchyLevel': 0,
          'paRecommended': pa.isparecommended,
          'fullPath': pa.path.replaceAll('>', '/'),
          'owner': pa.isowner
        }
      )) || [],
      'functionalAllPAs': item.allfunctionalpas?.map((pa) => (
        {
          'topicId': pa.topic_id,
          'keywordTopic': pa.keywordtopic,
          'topicNameAlias': pa.topic_name_alias,
          'parentId': pa.parent_id,
          'hierarchyLevel': 0,
          'paRecommended': pa.isparecommended,
          'fullPath': pa.path.replaceAll('>', '/'),
          'owner': pa.isowner
        }
      )) || [],
      'allSubjects': item.allsubjects?.map((sub) => (
        {
          'kpCmsId': '',
          'attachmentId': sub.attachment_id,
          'urlId': sub.url_id,
          'subjectId': sub.subject_id,
          'subjectName': sub.subject_name,
          'parentId': sub.parent_id,
          'hierarchyLevel': sub.hierarchy_level,
          'fullPath': sub.path.replaceAll('>', '/')
        }
      )) || [],
      'associatedLinks': item?.referenceurl_json?.map((assocLink) => ({ //this might need to be changed, can't validate due to unavailability of data
        title: assocLink?.relatedlinksname, 
        url: assocLink?.relatedlinksvalue,
        kpCmsId: assocLink?.kb_cms_id,
      })) || [],
      'associatedBundles': item?.kbobject_json?.map((kbobj) => ({
        kbCmsId: kbobj.kb_cms_id,
        kpCmsId: kbobj.kp_cms_id,
        materialId: kbobj.legacymaterialid,
        title: kbobj.title,
        updatedDate: kbobj.updateddate,
      })) || [],
      'allDownloads': item.download_json?.map((download) => (
        {
          'kpCmsId': '',
          'materialId': '',
          'attachmentId': '',
          'noOfDownloadsLast90Days': download.logical_downloads_90_days,
          'noOfDownloads': download.logical_downloads_all_time,
          'noOfTechnicalDownloadsLast90Days': download.technical_downloads_90,
          'noOfTechnicalDownloads': download.technical_downloads_all_time
        }
      )) || [],
      'createdBy': item.createdbyid,
      'openAccessInd': item.open_access_ind_v2,
      'source': item.source,
      'paRecommended': item.isrecommendedbypa,
      'hasHtmlPreview': item.has_html_preview,
      'hasImagesPreview': item.has_images_preview,
      'officeName': item.office_name,
      'fpaRecommendedList': item.fpauniquelist,
      'ipaRecommendedList': item.ipauniquelist,
      'language': item.language_name,
      'entitlement': {
        'download': item?.entitlement?.download,
        'preview': item?.entitlement?.preview
      },
      'frameworkTagging': item.framework_tagging,
      'taggedSlide_IDS': item.framework_taggingslide_ids
    };
  });
};

export const genAIEnblRetrievalRequest = (searchQuery, facet = null, numResults = 20) => {
  const filterConfig = Object.values(FILTERS.KNOWLEDGE_FILTERS.FILTER_BAR).filter((c) => c?.ELASTIC_PARAMETER_NAME);

  const getFilterClauses = () => {
    let filterFields = [];
    let dateFields = [];
      
    Object.entries(searchQuery).forEach(([key, value]) => {
      const config = filterConfig.find((c) => c.QUERY_PARAM === key);
      let fieldValue = '';
          
      if (config) {
        const isTreeFilter = config.ELASTIC_PARAMETER_NAME && 
        ['subjectpatopic', 'officetoregion', 'industrypatopic', 'functionalpatopic']
          .includes(config.ELASTIC_PARAMETER_NAME);

        if(['isrecommendedbypa', 'framework_tagging'].includes(config.ELASTIC_PARAMETER_NAME)) {
          fieldValue = true;
        }else {
          fieldValue = isTreeFilter ? value.join('|') : value.join(' OR ');
        }

        const fieldObject = {
          field_name: isTreeFilter
            ? `${config.ELASTIC_PARAMETER_NAME}.keyword` 
            : config.ELASTIC_PARAMETER_NAME,
          field_value: fieldValue
        };
              
        // Check if the field is "ssdate" or "original_published_date"
        if (['ssdate', 'original_published_date'].includes(config.ELASTIC_PARAMETER_NAME)) {
          if (value[0].includes('to')) {
            delete fieldObject.field_value;
            const { beginDateRaw, endDateRaw } = splitDateRangeString(value[0]);
            fieldObject.filter_date_start = `${beginDateRaw} 00:00:00`;
            fieldObject.filter_date_end = `${endDateRaw} 00:00:00`;
          }
          dateFields.push(fieldObject);
        } else {
          filterFields.push(fieldObject);
        }
      }
    });
      
    if (filterFields.length || dateFields.length) {
      const clause = {};
          
      if (filterFields.length) {
        clause.filter_fields = filterFields;
      }
          
      if (dateFields.length) {
        clause.date_fields = dateFields;
      }
          
      return [clause];
    }
      
    return [];
  };
    
  const getFacets = () => {
    let config = filterConfig;
    let facetSize = 31;

    if(facet) { //typeahead search filter for facet
      config = [facet];
      facetSize = 10000;
    }

    const facets = config.map((conf) => conf.ELASTIC_PARAMETER_NAME && {
      'name': conf.ELASTIC_PARAMETER_NAME,
      'field_data': ['isrecommendedbypa', 'subjectpatopic', 'industrypatopic', 'functionalpatopic', 'ssdate', 'original_published_date', 'officetoregion'].includes(conf.ELASTIC_PARAMETER_NAME) ? conf.ELASTIC_PARAMETER_NAME : `${conf.ELASTIC_PARAMETER_NAME}.facet`,
      'size': ['authorslist', 'hridlist', 'doc_type'].includes(conf.ELASTIC_PARAMETER_NAME) ? facetSize : 10000,
      'comma': ','
    });
  
    facets[facets.length - 1].comma = '';
    return facets;
  };

  return {
    'query': searchQuery?.query || '*',
    'data_repository': 'elastic',
    'method': 'bm25',
    'from_n': searchQuery?.fromN || 1,
    'top_n': numResults,
    'elastic_cluster': 'search_QA',
    'consumer_options': {
      'consumer_key': 'materials',
      'data_source': 'materials',
      'workspace_id': CONFIG.MATERIAL_SEARCH_WORKSPACE_ID
    },
    'search_options': {
      'filter_clauses': getFilterClauses(),
      'advancesearch': [],
      'facets': getFacets(),
      'default_sort': false
    }
  };
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getLoadMoreStatus: (state) => state[name].loadMorePending,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getEmpty: (state) => state[name].empty,
  getLastSearch: (state) => state[name].lastSearch,
  getPreviousSearch: (state) => state[name].previousSearch,
  getResults: (state) => state[name].results,
  getTotalCount: (state) => state[name].totalCount,
  getPageCount: (state) => state[name].pageCount,
  getResultsCurrentPage: (state) => state[name].currentPage,
  getActiveFilter: (state) => state[name].activeFilter,
  getAppliedFilters: (state) => state[name].appliedFilters,
  getAppliedQueryParams: (state) => state[name].appliedQueryParams,
  getFilters: (state) => state[name].filters,
  getQuery: (state) => state[name].query,
  getQueryIntents: (state) => state[name].queryIntents,
  getQueryIntentsApplied: (state) => state[name].qiApplied,
  getSorter: (state) => state[name].query[CONFIG.QUERY_PARAMS.SORTING_ORDER],
  getQiOriginal: (state) => state[name].qiOriginal,
};

const checkPARecommended = (filters) => {
  if (filters.find(
    (filter) => filter.parameterName === CONFIG.KNOWLEDGE_FILTERS.VIEW_CONTROLS.PA_RECOMMENDED.NAME && filter.refinerValues
  )?.refinerValues.find(
    (value) => value.display === 'PA/Topic'
  )) {
    return true;
  }
  return false;
};

const knowledgeSearch = (query, isAiView) => async (dispatch) => {
  const newSource = axios.CancelToken.source();
  sources.push(newSource);

  const queryPatched = { ...query };
  if (queryPatched.endDatePublished) {
    queryPatched.endDatePublished = moment(queryPatched.endDatePublished).startOf('day').add(1, 'd').format('YYYY-M-D');
  }
  if (queryPatched.endDateRevised) {
    queryPatched.endDateRevised = moment(queryPatched.endDateRevised).startOf('day').add(1, 'd').format('YYYY-M-D');
  }
  const searchQuery = flattenQuery(queryPatched);
  if (!isQueryIntentEnabled()) {
    searchQuery.disableQI = true;
  }

  dispatch({ type: API_KNOWLEDGE_SEARCH_PENDING, payload: { query: searchQuery } });
  const retrievalQuery = CONFIG.FEATURE_TOGGLES.USE_MATERIAL_GENAI_RETRIEVAL_API ? {...genAIEnblRetrievalRequest(searchQuery)} : searchQuery;


  try {
    const data = await axios.post(CONFIG.FEATURE_TOGGLES.USE_MATERIAL_GENAI_RETRIEVAL_API ? CONFIG.API_URL.GENAI_ENBL_RETRIEVAL('') : CONFIG.API_URL.KNOWLEDGE_SEARCH(''),
      retrievalQuery,
      { cancelToken: newSource.token }
    );

    if(CONFIG.FEATURE_TOGGLES.USE_MATERIAL_GENAI_RETRIEVAL_API) {
      //reconstruct tree structure
      if(data.aggregations){
        data.refiners = reconstructTreeStructure(data.aggregations, 'KNOWLEDGE_FILTERS');
      }
      if(data?.results) {
        data.doc = mapGenAIEnblRetrievalResponse(data.results);
      }
      data.TotalCount = data?.number_of_results || 0;
    }

    const filtersFromAPIAndConfig = formatFilters(data?.refiners, CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR);

    const paRecommendedFilter = data?.refiners ? checkPARecommended(data.refiners) : false;
    const payload = {
      ...data,
      query: searchQuery,
      results: data.doc?.map((d) => ({ ...d, id: d.kpCmsId || d.kbCmsId })) || [],
      filters: filtersFromAPIAndConfig,
      paRecommendedFilter,
      autoCorrect: {
        isSearchTermCorrected: false,
        correctedSearchTerm: ''
      }
    };
    if (data.didYouMeans && Array.isArray(data.didYouMeans)) {
      payload.autoCorrect.isSearchTermCorrected = data.didYouMeans.length > 0;
      payload.autoCorrect.correctedSearchTerm = data.didYouMeans.reduce(
        (accumulator, currentValue) => {
          return accumulator.replace(new RegExp(`(${escapeRegExp(currentValue.form)})`, 'g'), currentValue.correction);
        },
        query.query
      );
    }

    if (!isAiView && data.queryIntents?.length > 0) {
      dispatch({
        type: CHANGE_SEARCH_TERM_QI,
        payload: {
          query: getNewSearchTerm(data.queryIntents, searchQuery.query),
          original: searchQuery.query
        }
      });
    }

    dispatch({
      type: API_KNOWLEDGE_SEARCH_SUCCESS,
      payload
    });
  } catch (error) {
    if (error.cancelled) return;
    dispatch({ type: API_KNOWLEDGE_SEARCH_FAILURE, payload: error.toString() });
    throw error;
  }
};

export const actions = {
  knowledgeSearch,
  loadMoreFromSearch: (query, currentPage, currentLastResult) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    const queryPatched = { ...query };
    if (queryPatched.endDatePublished) {
      queryPatched.endDatePublished = moment(queryPatched.endDatePublished).startOf('day').add(1, 'd').format('YYYY-M-D');
    }
    if (queryPatched.endDateRevised) {
      queryPatched.endDateRevised = moment(queryPatched.endDateRevised).startOf('day').add(1, 'd').format('YYYY-M-D');
    }
    let loadMoreQuery = flattenQuery(queryPatched);
    if (!isQueryIntentEnabled()) {
      loadMoreQuery.disableQI = true;
    }
    if (CONFIG.FEATURE_TOGGLES.USE_MATERIAL_GENAI_RETRIEVAL_API){
      loadMoreQuery = {...genAIEnblRetrievalRequest({...loadMoreQuery, fromN: currentLastResult + 1})};
    }else {
      loadMoreQuery.resultsFromPage = parseInt(currentPage, 10) + CONFIG.DEFAULT_CURRENT_PAGE;
    }
    
    dispatch({
      type: API_KNOWLEDGE_LOAD_MORE_PENDING,
      payload: {
        query: loadMoreQuery
      }
    });

    try {
      const data = await axios.post(
        CONFIG.FEATURE_TOGGLES.USE_MATERIAL_GENAI_RETRIEVAL_API ? CONFIG.API_URL.GENAI_ENBL_RETRIEVAL('') : CONFIG.API_URL.KNOWLEDGE_SEARCH(''),
        loadMoreQuery,
        { cancelToken: newSource.token }
      );

      const payload = {
        ...data,
        doc: CONFIG.FEATURE_TOGGLES.USE_MATERIAL_GENAI_RETRIEVAL_API ? mapGenAIEnblRetrievalResponse(data.results) : data.doc?.map((d) => ({ ...d, id: d.kpCmsId || d.kbCmsId })),
        query: loadMoreQuery,
      };

      if(CONFIG.FEATURE_TOGGLES.USE_MATERIAL_GENAI_RETRIEVAL_API) {
        payload.TotalCount = data?.number_of_results || 0;
      }

      dispatch({ type: API_KNOWLEDGE_LOAD_MORE_SUCCESS, payload });
    } catch (error) {
      dispatch({ type: API_KNOWLEDGE_LOAD_MORE_FAILURE, payload: error.message });
      throw error;
    }
  },
  cancel: () => {
    sources.forEach((source) => source.cancel());
    return { type: API_SEARCH_CANCELLED };
  },
  setActiveFilter: (filterName) => async (dispatch) => dispatch({ type: SET_KNOWLEDGE_ACTIVE_FILTER_INDEX, payload: filterName }),
  setAppliedFilters: (queryParams) => setAppliedFilters(queryParams, CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR, SET_KNOWLEDGE_APPLIED_FILTERS_PENDING, SET_KNOWLEDGE_APPLIED_FILTERS_SUCCESS, SET_KNOWLEDGE_APPLIED_FILTERS_FAILED),
  changeSorter: (payload) => async (dispatch) => dispatch({ type: CHANGE_SORTER, payload }),
  setQueryIntentsApplied: () => async (dispatch) => dispatch({ type: SET_QUERY_INTENT_APPLIED }),
  setAutoCorrectMode: (payload) => async (dispatch) => dispatch({ type: SET_KNOWLEDGE_AUTO_CORRECT_STATE, payload }),
  undoQueryIntents: (payload) => async (dispatch) => dispatch({ type: SET_KNOWLEDGE_UNDO_QUERY_INTENTS, payload }),
  toggleAndFilter: (payload) => async (dispatch) => dispatch({ type: TOGGLE_AND_FILTER, payload }),
  applyAndFilters: () => async (dispatch) => dispatch({ type: APPLY_AND_FILTERS }),
  clearPendingAndFilters: () => async (dispatch) => dispatch({ type: CLEAR_PENDING_AND_FILTERS }),
  setShowChatbot: (showChatbot) => (dispatch) => dispatch({ type: KNOWLEDGE_SET_SHOW_CHATBOT, payload: showChatbot })
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_SLIDE_LEVEL_SEARCH_PENDING:
    case API_INIT_SLIDE_LEVEL_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_SEARCH_TERM: // dispatched from type-ahead
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload
        }
      };
    case API_KNOWLEDGE_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        empty: false,
        previousSearch: state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_KNOWLEDGE_SEARCH_LASTSEARCH_FAIL:
    case API_INIT_SLIDE_LEVEL_SEARCH_FAILURE:
    case API_SLIDE_LEVEL_SEARCH_FAILURE:
    case API_KNOWLEDGE_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case CHANGE_SEARCH_TERM_QI:
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload.query,
        },
        qiOriginal: action.payload.original
      };
    case API_KNOWLEDGE_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        empty: action.payload.results.length <= 0,
        results: action.payload.results,
        totalCount: action.payload.results.length <= 0 ? 0 : action.payload.TotalCount,
        currentPage: action.payload.CurrentPage,
        pageCount: action.payload.PageCount || 0,
        filters: action.payload.filters || [],
        paRecommendedFilter: action.payload.paRecommendedFilter,
        previousSearch: state.previousSearch.query?.query ? state.previousSearch : state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        },
        queryIntents: action.payload.queryIntents || [],
        qiApplied: false,
        autoCorrect: action.payload.autoCorrect,
        currentLastResult: action.payload.results?.length,
      };
    case API_KNOWLEDGE_SEARCH_LASTSEARCH:
      return {
        ...state,
        lastSearch: { query: { ...action.payload.query } }
      };
    case API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS:
    case API_SLIDE_LEVEL_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case API_KNOWLEDGE_LOAD_MORE_PENDING:
      return {
        ...state,
        loadMorePending: true,
        error: false,
        errorMessage: '',
        empty: false
      };
    case API_KNOWLEDGE_LOAD_MORE_FAILURE:
      return {
        ...state,
        loadMorePending: false,
        error: true,
        errorMessage: action.payload

      };
    case API_KNOWLEDGE_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMorePending: false,
        totalCount: action.payload.TotalCount,
        currentPage: action.payload.CurrentPage,
        pageCount: action.payload.PageCount,
        results: state.results.concat(action.payload.doc),
        queryIntents: action.payload.queryIntents || [],
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        },
        currentLastResult: state.currentLastResult + action.payload.doc?.length,
      };
    case SET_KNOWLEDGE_APPLIED_FILTERS_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_KNOWLEDGE_APPLIED_FILTERS_FAILED:
      return {
        ...state,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case SET_KNOWLEDGE_ACTIVE_FILTER_INDEX:
      return {
        ...state,
        activeFilter: action.payload
      };
    case SET_KNOWLEDGE_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case CHANGE_SORTER:
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.SORTING_ORDER]: action.payload
        }
      };
    case SET_QUERY_INTENT_APPLIED:
      return {
        ...state,
        qiApplied: true
      };
    case SET_KNOWLEDGE_AUTO_CORRECT_STATE:
      return {
        ...state,
        autoCorrectMode: action.payload
      };
    case SET_KNOWLEDGE_UNDO_QUERY_INTENTS:
      // See CHANGE_SEARCH_TERM_QI above
      return {
        ...state,
        queryIntents: [],
        qiApplied: false,
        qiOriginal: '',
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: state.qiOriginal,
        },
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload
      };
    case TOGGLE_AND_FILTER:
      const andFilterKey = action.payload;
      if (andFilterKey === undefined) {
        return state;
      }

      const pendingExists = Object.keys(state.pendingAndFilters).includes(andFilterKey);
      const andState = state.andFilters[andFilterKey];
      const toggled = pendingExists ? !state.pendingAndFilters[andFilterKey] : !andState;

      return {
        ...state,
        pendingAndFilters: {
          ...state.pendingAndFilters,
          [andFilterKey]: toggled
        }
      };
    case APPLY_AND_FILTERS:
      return {
        ...state,
        andFilters: { ...state.pendingAndFilters },
        pendingAndFilters: {}
      };
    case CLEAR_PENDING_AND_FILTERS:
      return {
        ...state,
        pendingAndFilters: {}
      };
    case KNOWLEDGE_SET_SHOW_CHATBOT:
      return {
        ...state,
        showChatbot: action.payload
      };
    default:
      return state;
  }
}