import axios from 'axios';
import CONFIG from 'config';
import { actions as notifyActions } from 'redux/components/notification/notification';

export const API_DOWNLOAD_PENDING = 'API_DOWNLOAD_PENDING';
export const API_DOWNLOAD_SUCCESS = 'API_DOWNLOAD_SUCCESS';
export const API_DOWNLOAD_FAILURE = ' API_DOWNLOAD_FAILURE';

export const name = 'bioResumeDownload';

const initialState = {
  profileDownload: [],
  loading: false,
  error: false,
  errorMessage: ''
};

export const selectors = { getDownload: (state) => state[name].profileDownload};

function downloadPropBio(payload){
  return async (dispatch) => {
    dispatch({ type: API_DOWNLOAD_PENDING, payload: { loading: true } });
    try {
      const data = await axios.get(payload, {
        headers: { 'x-api-key': CONFIG.CLIPPING_API_X_API_KEY },
      });
      const url = data?.documentData?.preSignedUrl;
      if (url) {
        triggerDownload(url);
        handleDownloadSuccess(dispatch, data);
      } else {
        handleDownloadError(dispatch, 'Invalid download URL');
      }
    } catch (error) {
      handleDownloadError(dispatch, error);
    }
  };
}

const triggerDownload = (url) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const handleDownloadSuccess = (dispatch, data) => {
  dispatch({ type: API_DOWNLOAD_SUCCESS, payload:data });
  dispatch(
    notifyActions.notifySuccess(
      CONFIG.DISPLAY_TEXT.PROPOSAL_BIO_NOTIF_MSGS.DOWNLOAD_COMPLETE_TITLE, 
      CONFIG.DISPLAY_TEXT.PROPOSAL_BIO_NOTIF_MSGS.DOWNLOAD_COMPLETE_TEXT
    )
  );
};

const handleDownloadError = (dispatch, error) => {
  dispatch({ type: API_DOWNLOAD_FAILURE, payload: error });
  dispatch(
    notifyActions.notifyError(
      CONFIG.DISPLAY_TEXT.PROPOSAL_BIO_NOTIF_MSGS.DOWNLOAD_FAILED_TITLE,
      CONFIG.DISPLAY_TEXT.PROPOSAL_BIO_NOTIF_MSGS.DOWNLOAD_FAILED_TEXT,
      error
    )
  );
};

export const actions = {
  fetchDownload: (proposalBioResumeId,type) => async (dispatch) => {
    dispatch({ type: API_DOWNLOAD_PENDING, payload: { loading: true } });
    try {
      const response = await axios.get(
        CONFIG.API_URL.DOWNLOAD_BIO_RESUME(proposalBioResumeId,type),
        
        {
          headers: {
            'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY,
            
          },
          
        });

      const tempLink = document.createElement('a');
      tempLink.href = response?.documentData?.preSignedUrl;
      document.body.appendChild(tempLink);
      tempLink.click();
      tempLink.remove();
      dispatch({ type: API_DOWNLOAD_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: API_DOWNLOAD_FAILURE, payload: error.message  });
    }
    
  },
  downloadPropBioNotif: (payload) => async (dispatch) => {
    dispatch(downloadPropBio(payload));
  }
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_DOWNLOAD_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case API_DOWNLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_DOWNLOAD_SUCCESS:
      return {
        ...state,   
        loading: false,
        profileDownload: {...action.payload}
        
      };
    default:
      return state;
  }
}