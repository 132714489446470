import CONFIG from 'config';
import axios from 'axios';
import { buildQueryString, escapeRegExp, flattenQuery, queryObjToQueryString, queryWithSearchParamsOnly } from 'utils/string-mapper/string-mapper';

import { setAppliedFilters, formatFilters, reconstructTreeStructure, splitDateRangeString } from 'utils/filters/filters';

import FILTERS from 'config-filters';
import { getNewSearchTerm } from 'utils/queryintent/queryintent';

export const name = 'bcgInternal';

export const API_BCG_INTERNAL_SEARCH_PENDING = 'API_BCG_INTERNAL_SEARCH_PENDING';
export const API_BCG_INTERNAL_SEARCH_FAILURE = 'API_BCG_INTERNAL_SEARCH_FAILURE';
export const API_BCG_INTERNAL_SEARCH_SUCCESS = 'API_BCG_INTERNAL_SEARCH_SUCCESS';
export const API_SEARCH_CANCELLED = 'API_SEARCH_CANCELLED';
export const API_BCG_INTERNAL_LOAD_MORE_PENDING = 'API_BCG_INTERNAL_LOAD_MORE_PENDING';
export const API_BCG_INTERNAL_LOAD_MORE_FAILURE = 'API_BCG_INTERNAL_LOAD_MORE_FAILURE';
export const API_BCG_INTERNAL_LOAD_MORE_SUCCESS = 'API_BCG_INTERNAL_LOAD_MORE_SUCCESS';
export const API_SLIDE_LEVEL_SEARCH_PENDING = 'API_SLIDE_LEVEL_SEARCH_PENDING';
export const API_SLIDE_LEVEL_SEARCH_FAILURE = 'API_SLIDE_LEVEL_SEARCH_FAILURE';
export const API_SLIDE_LEVEL_SEARCH_SUCCESS = 'API_SLIDE_LEVEL_SEARCH_SUCCESS';
export const API_INIT_SLIDE_LEVEL_SEARCH_PENDING = 'API_INIT_SLIDE_LEVEL_SEARCH_PENDING';
export const API_INIT_SLIDE_LEVEL_SEARCH_FAILURE = 'API_INIT_SLIDE_LEVEL_SEARCH_FAILURE';
export const API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS = 'API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS';
export const SHOW_DOC_VIZ = 'SHOW_DOC_VIZ';
export const CLOSE_DOC_VIZ = 'CLOSE_DOC_VIZ';
export const LOADING_DOC_VIZ = 'LOADING_DOC_VIZ';
export const SET_NEW_SEARCH_TRIGGERED = 'SET_NEW_SEARCH_TRIGGERED';
export const SET_FRESH_PAGE_LOAD = 'SET_FRESH_PAGE_LOAD';
export const SET_BCG_INTERNAL_APPLIED_FILTERS_PENDING = 'SET_BCG_INTERNAL_APPLIED_FILTERS_PENDING';
export const SET_BCG_INTERNAL_APPLIED_FILTERS_SUCCESS = 'SET_BCG_INTERNAL_APPLIED_FILTERS_SUCCESS';
export const SET_BCG_INTERNAL_APPLIED_FILTERS_FAILED = 'SET_BCG_INTERNAL_APPLIED_FILTERS_FAILED';
export const SET_BCG_INTERNAL_ACTIVE_FILTER_INDEX = 'SET_BCG_INTERNAL_ACTIVE_FILTER_INDEX';
export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM';
export const SET_BCG_INTERNAL_APPLIED_FILTERS_ARRAY = 'SET_BCG_INTERNAL_APPLIED_FILTERS_ARRAY';
export const CHANGE_SORTER = 'CHANGE_SORTER';
export const SET_QUERY_INTENT_APPLIED = 'SET_QUERY_INTENT_APPLIED';
export const API_BCG_INTERNAL_SEARCH_LASTSEARCH = 'API_BCG_INTERNAL_SEARCH_LASTSEARCH';
export const API_BCG_INTERNAL_SEARCH_LASTSEARCH_FAIL = 'API_BCG_INTERNAL_SEARCH_LASTSEARCH_FAIL';
export const CHANGE_SEARCH_TERM_QI = 'CHANGE_SEARCH_TERM_QI';
export const SET_BCG_INTERNAL_AUTO_CORRECT_STATE = 'SET_BCG_INTERNAL_AUTO_CORRECT_STATE';
export const SET_REGION_AUTOAPPLIED = 'SET_REGION_AUTOAPPLIED';
export const SET_BCG_INTERNAL_UNDO_QUERY_INTENTS = 'SET_BCG_INTERNAL_UNDO_QUERY_INTENTS';

const isProdOrStg = () => CONFIG.ENVIRONMENT == 'prod' || CONFIG.ENVIRONMENT == 'stg';

const sources = [];
const initialState = {
  loading: true,
  loadMorePending: false,
  error: false,
  errorMessage: '',
  empty: true,
  previousSearch: { query: { } },
  lastSearch: { query: { } },
  results: [],
  totalCount: 0,
  pageCount: 0,
  currentPage: CONFIG.DEFAULT_CURRENT_PAGE,
  filters: [],
  appliedQueryParams: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilters: {}, // object ready for qs to convert to a string in string-mapper.js
  activeFilter: '',
  query: queryWithSearchParamsOnly(),
  queryIntents: [],
  qiApplied: false,
  qiOriginal: '',
  autoCorrectMode: true,
  autoCorrect: {
    isSearchTermCorrected: false,
    correctedSearchTerm: ''
  },
  paRecommendedFilter: false,
  regionAutoApplied: false,
  currentLastResult: 0,
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getLoadMoreStatus: (state) => state[name].loadMorePending,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getEmpty: (state) => state[name].empty,
  getLastSearch: (state) => state[name].lastSearch,
  getPreviousSearch: (state) => state[name].previousSearch,
  getResults: (state) => state[name].results,
  getTotalCount: (state) => state[name].totalCount,
  getPageCount: (state) => state[name].pageCount,
  getResultsCurrentPage: (state) => state[name].currentPage,
  getActiveFilter: (state) => state[name].activeFilter,
  getAppliedFilters: (state) => state[name].appliedFilters,
  getAppliedQueryParams: (state) => state[name].appliedQueryParams,
  getFilters: (state) => state[name].filters,
  getQuery: (state) => state[name].query
};

export const mapGenAIEnblRetrievalResponse = (results) => {
  return results?.map((item) => {

    const isMicrosite = item.doc_type === 'Microsites';
    const kpCmsId = isMicrosite ? item.url : item.crawl_item_id;
    const officeName = Array.isArray(item.office_name) ? item.office_name?.join(';') : item.office_name; //handling field discripancy between stage and lower envs
    const internalOfficeName = isMicrosite ? officeName : Array.isArray(item.internalofficename) ? item.internalofficename?.join(';') : item.internalofficename; //handling field discripancy between stage and lower envs

    return {
      'docId': kpCmsId,
      'docRank': item.rank,
      'docType': item.doc_type,
      'title': item.title,
      'materialDesc': item.material_desc || '',
      'materialDescHtml': item.material_desc_html || '',
      'resultType': isMicrosite ? item.doc_type : item.contenttype,
      'modified': item.doc_updated_date,
      'dateRevised': item.doc_updated_date,
      'datePublished': item.original_published_date,
      'globalRelevance': 1,
      'fileName': item.filename,
      'materialId': item.material_id,
      'attachmentId': item.material_attachment_id,
      'kbCmsId': item.kb_cms_id,
      'kpCmsId': kpCmsId,
      'allAuthors': item.authors_json?.map((author) => (
        {
          'staffId': 0,
          'firstName': author.firstname,
          'lastName': author.lastname,
          'email': author.email,
          'role': '',
          'roleSortOrder': 0,
          'hrEmployeeId': author.hr_emp_id,
          'additionalContact': author.isadditionalcontact
        }
      )) || [],
      'urlTitle': item.url_title,
      'materialUrl': isMicrosite ? item.url : item.material_url,
      'industryAllPAs': item.allindustrypas?.map((pa) => (
        {
          'topicId': pa.topic_id,
          'keywordTopic': pa.keywordtopic,
          'topicNameAlias': pa.topic_name_alias,
          'parentId': pa.parent_id,
          'hierarchyLevel': 0,
          'paRecommended': pa.isparecommended,
          'fullPath': pa.path.replaceAll('>', '/'),
          'owner': pa.isowner
        }
      )) || [],
      'functionalAllPAs': item.allfunctionalpas?.map((pa) => (
        {
          'topicId': pa.topic_id,
          'keywordTopic': pa.keywordtopic,
          'topicNameAlias': pa.topic_name_alias,
          'parentId': pa.parent_id,
          'hierarchyLevel': 0,
          'paRecommended': pa.isparecommended,
          'fullPath': pa.path.replaceAll('>', '/'),
          'owner': pa.isowner
        }
      )) || [],
      'allSubjects': item.allsubjects?.map((sub) => (
        {
          'kpCmsId': '',
          'attachmentId': '',
          'urlId': '',
          'subjectId': sub.subject_id,
          'subjectName': sub.subject_name,
          'parentId': sub.parent_id,
          'hierarchyLevel': 0,
          'fullPath': sub.path.replaceAll('>', '/')
        }
      )) || [],
      'associatedLinks': item?.referenceurl_json?.map((assocLink) => ({ //this might need to be changed, can't validate due to unavailability of data
        title: assocLink?.relatedlinksname, 
        url: assocLink?.relatedlinksvalue,
        kpCmsId: assocLink?.kb_cms_id,
      })) || [],
      'associatedBundles': item.kbobject_json?.map((bundle) => (
        {
          'kbCmsId': bundle.kb_cms_id,
          'kpCmsId': bundle.kp_cms_id,
          'materialId': bundle.legacymaterialid,
          'title': bundle.tile,
          'updatedDate': bundle.updateddate
        }
      )),
      'allDownloads': item.download_json?.map((download) => (
        {
          'kpCmsId': '',
          'materialId': '',
          'attachmentId': '',
          'noOfDownloadsLast90Days': download.logical_downloads_90_days,
          'noOfDownloads': download.logical_downloads_all_time,
          'noOfTechnicalDownloadsLast90Days': download.technical_downloads_90,
          'noOfTechnicalDownloads': download.technical_downloads_all_time
        }
      )) || [],
      'createdBy': item.createdbyid,
      'openAccessInd': item.open_access_ind.toLowerCase() === 'true',
      'source': item.source,
      'paRecommended': item.isrecommendedbypa,
      'hasHtmlPreview': item.has_html_preview,
      'hasImagesPreview': item.has_images_preview,
      'officeName': officeName,
      'internalOfficeName': internalOfficeName,
      'region': item.region,
      'regionCountry': item.region_country_name[0],
      'fpaRecommendedList': item.fpauniquelist,
      'ipaRecommendedList': item.ipauniquelist,
      'language': item.language_name,
      'entitlement': {
        'download': item.entitlement.download,
        'preview': item.entitlement.preview
      },
      'frameworkTagging': item.framework_tagging,
      'taggedSlide_IDS': item.framework_taggingslide_ids,
      'rootPage': item.root_page
    };
  });
};

export const genAIConsumerOptions = () => ({
  'elastic_cluster': isProdOrStg() ? 'search_PROD' : 'search_QA',
  'consumer_options': {
    'consumer_key': 'internal',
    'data_source': 'internal',
    'workspace_id': CONFIG.BCG_INTERNAL_SEARCH_WORKSPACE_ID,
  }
});

export const genAIEnblRetrievalRequest = (searchQuery, facet = null, numResults = 20, skipFacets = false) => {
  const filterConfig = Object.values(FILTERS.BCG_INTERNAL_FILTERS.FILTER_BAR).filter((c) => c?.ELASTIC_PARAMETER_NAME && !c?.ONLY_ADVANCED_SEARCH_FILTER);

  const getFilterClauses = () => {
    let filterFields = [];
    let dateFields = [];
    let fieldObject = {};
    
    Object.entries(searchQuery).forEach(([key, value]) => {
      const config = filterConfig.find((c) => c.QUERY_PARAM === key);

      if (config) {
        const isTreeFilter = config.ELASTIC_PARAMETER_NAME && 
        ['officetoregion', 'functionalpatopic'].includes(config.ELASTIC_PARAMETER_NAME);

        fieldObject = {
          field_name: isTreeFilter ? `${config.ELASTIC_PARAMETER_NAME}.keyword` : config.ELASTIC_PARAMETER_NAME,
          field_value: value.join('|')
        };
        
        if (config.ELASTIC_PARAMETER_NAME === 'doc_updated_date') {
          dateFields.push(fieldObject);
        } else {
          filterFields.push(fieldObject);
        }
      } 
    });

    if(searchQuery?.officeName) {
      filterFields.push(
        {
          field_name: 'internalofficename',
          field_value: searchQuery?.officeName?.join(' OR ')
        });
    }
    
    if (filterFields.length || dateFields.length) {
      const clause = {};
        
      if (filterFields.length) {
        clause.filter_fields = filterFields;
      }
        
      if (dateFields.length) {
        clause.date_fields = dateFields;
      }
        
      return [clause];
    }
    
    return [];
  };

  const getAdvSearch = () => {
    return [];
  };

  const getFacets = () => {
    let config = filterConfig;
    let facetSize = 30;

    if(facet) { //typeahead search filter for facet
      config = [facet];
      facetSize = 10000;
    }

    const facets = config.map((conf) => conf.ELASTIC_PARAMETER_NAME && {
      'name': conf.ELASTIC_PARAMETER_NAME,
      'field_data': conf.ELASTIC_PARAMETER_NAME === 'doc_type' ? `${conf.ELASTIC_PARAMETER_NAME}.facet` : conf.ELASTIC_PARAMETER_NAME,
      'size': ['doc_type', 'doc_updated_date']
        .includes(conf.ELASTIC_PARAMETER_NAME) ? facetSize : 10000,
      'comma': ','
    });
  
    facets[facets.length - 1].comma = '';
    return facets;
  };

  return {
    'query': searchQuery?.query || '*',
    'data_repository': 'elastic',
    'method': 'bm25',
    'from_n': searchQuery?.fromN || 1,
    'top_n': numResults,
    'doc_level_search':true,
    ...genAIConsumerOptions(),
    'auto_suggest': searchQuery?.enableAutoCorrect, 
    'search_options': {
      'filter_clauses': getFilterClauses(),
      'advancesearch': getAdvSearch(),
      ...(!skipFacets && {'facets': getFacets()}),
      'default_sort': searchQuery?.sortingOrder === 'relevance.desc' ? false : true
    }
  };
};

const bcgInternalSearch = (reload, brand, scope, query) => async (dispatch) => {
  const newSource = axios.CancelToken.source();
  sources.push(newSource);

  const searchQuery = flattenQuery(query);

  dispatch({ type: API_BCG_INTERNAL_SEARCH_PENDING, payload: { query: searchQuery } });

  const retrievalQuery = CONFIG.FEATURE_TOGGLES.USE_BCGI_GENAI_RETRIEVAL_API ? {...genAIEnblRetrievalRequest(searchQuery)} : searchQuery;

  try {
    const data = await axios.post(CONFIG.FEATURE_TOGGLES.USE_BCGI_GENAI_RETRIEVAL_API ? CONFIG.API_URL.GENAI_ENBL_RETRIEVAL('') : (CONFIG.API_URL.BCG_INTERNAL_SEARCH('')),
      retrievalQuery,
      { cancelToken: newSource.token }
    );

    if (data) { // note: possiblity of removing this if condition if empty parameters are specified in the success action
      if (CONFIG.FEATURE_TOGGLES.USE_BCGI_GENAI_RETRIEVAL_API) {
        data.refiners = data.aggregations ? reconstructTreeStructure(data.aggregations, 'BCG_INTERNAL_FILTERS') : data.refiners;
        data.doc = data.results ? mapGenAIEnblRetrievalResponse(data.results) : data.doc;
        data.TotalCount = data?.number_of_results || 0;
      }

      const filtersFromAPIAndConfig = formatFilters(data?.refiners, CONFIG.BCG_INTERNAL_FILTERS.FILTER_BAR);

      const payload = {
        ...data,
        query: searchQuery,
        results: data.doc?.map((d) => ({ ...d, id: d.kpCmsId || d.kbCmsId })),
        filters: filtersFromAPIAndConfig,
        autoCorrect: {
          isSearchTermCorrected: false,
          correctedSearchTerm: ''
        },
      };
      if(CONFIG.FEATURE_TOGGLES.USE_BCGI_GENAI_RETRIEVAL_API) {
        const isQueryCorrected = query?.query ? data.corrected_query !== '' && query.query !== data.corrected_query : false;
        payload.autoCorrect.isSearchTermCorrected = isQueryCorrected;
        payload.autoCorrect.correctedSearchTerm = isQueryCorrected ? data.corrected_query : '';
      } 
      if (data.didYouMeans && Array.isArray(data.didYouMeans)) {
        payload.autoCorrect.isSearchTermCorrected = data.didYouMeans.length > 0;
        payload.autoCorrect.correctedSearchTerm  = data.didYouMeans.reduce(
          (accumulator, currentValue) => {
            return accumulator.replace(new RegExp(`(${escapeRegExp(currentValue.form)})`, 'g') , currentValue.correction);
          },
          query.query
        );
      }

      if (data.queryIntents?.length > 0) {
        if (scope === CONFIG.SEARCH.SCOPES.BCG_INTERNAL) {
          dispatch({
            type: CHANGE_SEARCH_TERM_QI,
            payload: {
              query: getNewSearchTerm(data.queryIntents, query.query),
              original: query.query
            }
          });
        } else {
          dispatch({
            type: CHANGE_SEARCH_TERM_QI,
            payload: {
              query: query.query,
              original: query.query
            }
          });
        }
      }

      dispatch({
        type: API_BCG_INTERNAL_SEARCH_SUCCESS,
        payload
      });
    } else {
      const payload = {
        ...data,
        query: searchQuery,
        results: [],
        filters: [],
        autoCorrect: {
          isSearchTermCorrected: false,
          correctedSearchTerm: ''
        },
      };
      dispatch({
        type: API_BCG_INTERNAL_SEARCH_SUCCESS,
        payload
      });
    }
  } catch (error) {
    if (error.cancelled) return;
    dispatch({
      type: API_BCG_INTERNAL_SEARCH_FAILURE,
      payload: error.toString()
    });
    throw error;
  }
};

export const actions = {
  bcgInternalSearch,
  loadMoreFromSearch: (query, currentPage, currentLastResult) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    let loadMoreQuery = flattenQuery(query);
    if (CONFIG.FEATURE_TOGGLES.USE_BCGI_GENAI_RETRIEVAL_API){
      loadMoreQuery = {...genAIEnblRetrievalRequest({...loadMoreQuery, fromN: currentLastResult + 1})};
    }else {
      loadMoreQuery.resultsFromPage = parseInt(currentPage, 10) + CONFIG.DEFAULT_CURRENT_PAGE;
    }

    dispatch({
      type: API_BCG_INTERNAL_LOAD_MORE_PENDING,
      payload: {
        query: loadMoreQuery
      }
    });

    try {
      const data = await axios.post(
        CONFIG.FEATURE_TOGGLES.USE_BCGI_GENAI_RETRIEVAL_API ? CONFIG.API_URL.GENAI_ENBL_RETRIEVAL('') : CONFIG.API_URL.BCG_INTERNAL_SEARCH(''),
        loadMoreQuery,
        { cancelToken: newSource.token }
      );

      const payload = {
        ...data,
        query: loadMoreQuery,
        doc: CONFIG.FEATURE_TOGGLES.USE_BCGI_GENAI_RETRIEVAL_API ? mapGenAIEnblRetrievalResponse(data.results) : data.doc?.map((d) => ({ ...d, id: d.kpCmsId || d.kbCmsId })),

      };
      if(CONFIG.FEATURE_TOGGLES.USE_BCGI_GENAI_RETRIEVAL_API) {
        payload.TotalCount = data?.number_of_results || 0;
      }

      dispatch({ type: API_BCG_INTERNAL_LOAD_MORE_SUCCESS, payload });
    } catch (error) {
      dispatch({ type: API_BCG_INTERNAL_LOAD_MORE_FAILURE, payload: error.message });
      throw error;
    }
  },
  cancel: () => {
    sources.forEach((source) => source.cancel());
    return { type: API_SEARCH_CANCELLED };
  },
  setActiveFilter: (filterName) => async (dispatch) => dispatch({ type: SET_BCG_INTERNAL_ACTIVE_FILTER_INDEX, payload: filterName }),
  setAppliedFilters: (queryParams) => setAppliedFilters(queryParams, CONFIG.BCG_INTERNAL_FILTERS.FILTER_BAR, SET_BCG_INTERNAL_APPLIED_FILTERS_PENDING, SET_BCG_INTERNAL_APPLIED_FILTERS_SUCCESS, SET_BCG_INTERNAL_APPLIED_FILTERS_FAILED),
  changeSorter: (payload) => async (dispatch) => dispatch({ type: CHANGE_SORTER, payload }),
  setQueryIntentsApplied: () => async (dispatch) => dispatch({ type: SET_QUERY_INTENT_APPLIED }),
  setAutoCorrectMode: (payload) => async (dispatch) => dispatch({ type: SET_BCG_INTERNAL_AUTO_CORRECT_STATE, payload }),
  setRegionAutoApplied: (isSet) => (dispatch) => dispatch({ type: SET_REGION_AUTOAPPLIED, payload: isSet }),
  undoQueryIntents: (payload) => async (dispatch) => dispatch({ type: SET_BCG_INTERNAL_UNDO_QUERY_INTENTS, payload })
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case API_SLIDE_LEVEL_SEARCH_PENDING:
    case API_INIT_SLIDE_LEVEL_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
      };
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        query: queryWithSearchParamsOnly()
      };
    case CHANGE_SEARCH_TERM: // dispatched from type-ahead
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload
        }
      };
    case API_BCG_INTERNAL_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
        empty: false,
        previousSearch: state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_BCG_INTERNAL_SEARCH_LASTSEARCH_FAIL:
    case API_INIT_SLIDE_LEVEL_SEARCH_FAILURE:
    case API_SLIDE_LEVEL_SEARCH_FAILURE:
    case API_BCG_INTERNAL_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case CHANGE_SEARCH_TERM_QI:
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload.query,
        },
        qiOriginal: action.payload.original
      };
    case API_BCG_INTERNAL_SEARCH_SUCCESS:
      const resultsCount = () => {
        return action.payload.results?.length ? action.payload.results?.length : 0;
      };
      
      return {
        ...state,
        loading: false,
        error: false,
        empty: resultsCount() <= 0,
        results: action.payload.results,
        totalCount: resultsCount() <= 0 ? 0 : action.payload.TotalCount,
        currentPage: action.payload.CurrentPage,
        pageCount: action.payload.PageCount,
        filters: action.payload.filters,
        previousSearch: state.previousSearch.query?.query ? state.previousSearch : state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        },
        queryIntents: action.payload.queryIntents,
        qiApplied: false,
        autoCorrect: action.payload.autoCorrect,
        currentLastResult: action.payload.results?.length,
      };
    case API_BCG_INTERNAL_SEARCH_LASTSEARCH:
      return {
        ...state,
        lastSearch: { query: { ...action.payload.query } }
      };
    case API_INIT_SLIDE_LEVEL_SEARCH_SUCCESS:
    case API_SLIDE_LEVEL_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case API_BCG_INTERNAL_LOAD_MORE_PENDING:
      return {
        ...state,
        loadMorePending: true,
        error: false,
        errorMessage: '',
        empty: false
      };
    case API_BCG_INTERNAL_LOAD_MORE_FAILURE:
      return {
        ...state,
        loadMorePending: false,
        error: true,
        errorMessage: action.payload
      };
    case API_BCG_INTERNAL_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMorePending: false,
        totalCount: action.payload.TotalCount,
        currentPage: action.payload.CurrentPage,
        pageCount: action.payload.PageCount,
        results: state.results.concat(action.payload.doc),
        queryIntents: action.payload.queryIntents || [],
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        },
        currentLastResult: state.currentLastResult + action.payload.doc?.length,
      };
    case SET_BCG_INTERNAL_APPLIED_FILTERS_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_BCG_INTERNAL_APPLIED_FILTERS_FAILED:
      return {
        ...state,
        loading: false,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case SET_BCG_INTERNAL_ACTIVE_FILTER_INDEX:
      return {
        ...state,
        activeFilter: action.payload
      };
    case SET_BCG_INTERNAL_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case CHANGE_SORTER:
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.SORTING_ORDER]: action.payload
        }
      };
    case SET_QUERY_INTENT_APPLIED:
      return {
        ...state,
        qiApplied: true
      };
    case SET_BCG_INTERNAL_AUTO_CORRECT_STATE:
      return {
        ...state,
        autoCorrectMode: action.payload
      };
    case SET_REGION_AUTOAPPLIED:
      return {
        ...state,
        regionAutoApplied: action.payload
      };
    case SET_BCG_INTERNAL_UNDO_QUERY_INTENTS:
      // See CHANGE_SEARCH_TERM_QI above
      return {
        ...state,
        queryIntents: [],
        qiApplied: false,
        qiOriginal: '',
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: state.qiOriginal,
        },
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload
      };
    default:
      return state;
  }
}